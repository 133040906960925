.footer {
  width: 97%;
  min-width: 20.75rem;
  display: flex;
  flex-direction: column;
  margin: auto auto 4.875rem auto;

  &__item {
    width: 91%;
    min-width: 18.875rem;
    height: 2.75rem;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    box-sizing: border-box;
    background: #162348;
    border: 1px solid #087dff;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0 1rem;
    color: white;
    margin: 0 auto 0.25rem auto;
    cursor: pointer;
  }
  &__image {
    width: 1.2rem;
    margin-right: 1rem;
    // * {
    //   fill: #333;
    // }
  }
  &__title {
    background: #0c4593;
    border-radius: 5px;
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 0.7rem;
    box-sizing: border-box;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
}
