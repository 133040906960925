.total {
  color: white;

  &__title {
    background: linear-gradient(to bottom, #0e70b9 0, #005693 100%);
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 0.7rem;
    box-sizing: border-box;
    font-weight: 400;
    opacity: 0;
  }
  &__td {
    width: 25%;
    text-align: center;
    &_type {
      &_left {
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &_right {
        /* text-align: right; */
      }
    }
  }
  &__input-block {
    box-sizing: border-box;
    margin: 0 auto;
    width: 94%;
    min-width: 20rem;
    &:nth-child(2) {
      margin-bottom: 1.25rem;
    }
  }
  &__image {
    width: 1.4rem;
    margin-right: 0.5rem;
  }
  &__bottom {
    box-sizing: border-box;
    padding: 2.5rem 0;
    width: 70%;
    min-width: 15rem;
    margin: 0 auto;
  }
  &__button {
    width: 100%;
    height: 2.8rem;
    background: url(./img/background-button.jpg);
    background-position: 0 0;
    background-size: 100% 100%;
    border-radius: 3.99319px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
    cursor: pointer;
    // &_isLoading {
    //   background: #11541c;
    //   cursor: not-allowed;
    // }
  }
  &__ok {
    width: 1.8rem;
    position: absolute;
    right: 1rem;
  }
  &__result-header {
    // background: linear-gradient(to bottom,#0e70b9 0,#005693 100%);
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background: #162348;
    border: 1px solid #087dff;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0 1.5rem;
    font-size: 0.9rem;
    font-weight: 400;
    margin-bottom: 1.25rem;
  }
  &__result {
    margin: 0 auto;
    width: 94%;
    min-width: 20rem;
  }
  &__row {
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 0.7rem;
    font-size: 0.8rem;
    font-weight: 400;
    background: #162348;
    border: 1px solid #087dff;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    &_type {
      &_bold {
        font-weight: bold;
      }
    }
  }
  &__label {
    font-weight: bold;
    margin-bottom: 0.625rem;
    text-transform: capitalize;
  }

  & .react-datepicker-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__custom-date-input {
    background: #0c4593;
    border-radius: 5px;
    width: 100%;
    height: 2.625rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 1.3125rem;
  }
  &__custom-date-input-value {
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
  &__custom-date-input-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-svg {
      width: 1.3125rem;
      height: 1.3125rem;
    }
  }
}
