.menu-item {
  width: 97%;
  min-width: 20.75rem;
  height: 2.5rem;
  color: white;
  background: #0c4593;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 0.875rem;
  margin: 0 auto 0.5rem auto;
  font-weight: bold;
  text-transform: capitalize;

  > * {
    fill: #084772;
  }

  &__image {
    display: block;
    transform: rotate(180deg);
    &-svg {
      width: 1rem;
      height: 1rem;
    }
    &_open {
      transform: rotate(0deg);
    }
  }
  &__element-list {
    width: 85%;
    min-width: 18.75rem;
    box-sizing: border-box;
    margin: 0 auto 0.5rem auto;
    &:last-child {
      padding-bottom: 0;
    }
  }
  &__element {
    // box-shadow: 0 1px 3px rgba(0,0,0,.15);
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    background: #162348;
    border: 1px solid #087dff;
    border-radius: 5px;
    padding: 0 1rem 0 1.5rem;
    margin-bottom: 0.25rem;
    color: white;
    // &:last-child {
    //   border-bottom: 1px solid #ddd;
    // }
  }

  &__arrow {
    display: block;
    &-svg {
      width: 1rem;
      height: 1rem;
      & path {
        fill: white;
      }
    }
  }
}
