.transaction-item {
  width: 100%;
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr;
  align-items: center;
  background: #162348;
  border: 1px solid #087dff;
  box-sizing: border-box;
  border-radius: 5px;
  height: 2.5rem;
  color: #fff;
  padding: 0 1rem;
  box-sizing: border-box;
  margin-bottom: 0.5rem;

  &_needBalance {
    grid-template-columns: 2.5fr 1.3fr 1fr 1fr;
  }

  &__column-amount {
    color: #c22326;
  }
  &__column,
  &__column-amount {
    min-width: 0;
    font-size: 0.7857rem;
    width: 100%;
    text-align: center;
    overflow: auto;

    &_align_left {
      text-align: left;
    }

    &_colored {
      color: #00890d;
    }
  }
}
