.transaction-history {
  color: white;
  padding-bottom: 2rem;

  &__filter {
    &-block {
      width: 94%;
      min-width: 20rem;
      margin: 0 auto 2.5rem auto;
    }
    &-item {
      margin-bottom: 1.125rem;
    }
    &-title {
      margin-bottom: 0.625rem;
      font-weight: bold;
      text-transform: capitalize;
    }
  }

  /* &__input {
    border: 1px solid #ddd;
    height: 2.2857rem;
    background: #fff;
    display: flex;
    align-items: center;
    position: relative;
  } */

  &__calendar {
    position: absolute;
    top: calc(505 - 0.85rem);
    right: 0.5rem;
    width: 1.7143rem;
    height: 1.7143rem;
    // background: #424242;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &-svg {
      width: 1.25rem;
    }
  }

  /* .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
  }

  .react-datepicker__input-container {
    height: 100%;
    input {
      width: 100%;
      height: 2.2857rem;
      outline: none;
      border: none;
      padding: 0;
      padding-left: 0.5rem;
      box-sizing: border-box;
    }
  } */

  &__search-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: url(./img/background-button.jpg);
    background-position: 0 0;
    background-size: 100% 100%;
    border-radius: 3.99319px;
    height: 2.7143rem;
    width: 74.25%;
    min-width: 15rem;
    margin: 1.25rem auto 2.5rem auto;
    cursor: pointer;
  }

  &__search-button-icon {
    position: absolute;
    top: calc(50% - 0.78rem);
    right: 0.5rem;
    width: 1.5714rem;
    height: 1.5714rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 100%;
    &-svg {
      width: 1.1429rem;
      height: 1.1429rem;
      * {
        fill: #fff;
      }
    }
  }

  &__search-block {
    // padding: 0.7rem;
    width: 86%;
    min-width: 18.75rem;
    display: flex;
    margin: 0 auto 3.125rem;
    position: relative;
    background: #0c4593;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 0.5625rem 1rem;
  }
  &__search-icon {
    display: flex;
    position: absolute;
    left: 1.7rem;
    top: calc(50% - 0.5rem);
    &-svg {
      width: 1.25rem;

      & path {
        fill: #0a2239;
      }
    }
  }
  &__search-input {
    width: 70%;
    margin-right: 0.625rem;
    & input {
      background: white !important;
      color: #0a2239;
    }
  }
  &__button {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #162348;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: white;
  }

  &__users-header1,
  &__users-header2 {
    // background: linear-gradient(to bottom,#0e70b9 0,#005693 100%);
    height: 2.5rem;
    display: grid;
    grid-template-columns: 2.5fr 1.3fr 1fr 1fr;
    align-items: center;
    // color: #fff;
    padding: 0 1rem;
    box-sizing: border-box;
    background: #162348;
    border: 1px solid #087dff;
    box-sizing: border-box;
    border-radius: 5px;
    text-transform: capitalize;
    margin-bottom: 1rem;
  }
  &__users-header2 {
    grid-template-columns: 2.5fr 1fr 1fr;
  }
  &__result-wrapper {
    width: 94%;
    min-width: 20rem;
    margin: 0 auto 2.5rem auto;
  }

  &__user-header-item {
    min-width: 0;
    font-size: 0.7143rem;
    width: 100%;
    text-align: center;

    &_align_left {
      text-align: left;
    }
  }

  // .transaction-item:nth-child(2n) {
  //   background: #f8f8f8f8;
  // }

  & .react-datepicker-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__custom-date-input {
    background: #0c4593;
    border-radius: 5px;
    width: 100%;
    height: 2.625rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 1.3125rem;
  }
  &__custom-date-input-value {
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
  &__custom-date-input-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-svg {
      width: 1.3125rem;
      height: 1.3125rem;
    }
  }

  &__list {
  }
  &__list-title {
    margin: 0.625rem 0 0.625rem 0;
  }
  &__items {
  }
  &__total {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  &__total-item {
    box-sizing: border-box;
    min-width: 15%;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__total-value {
    margin-left: 0.5rem;
  }
}
