.app {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background: #0c101e;
}

@import url("https://fonts.googleapis.com/css?family=Roboto:100,400,700,900");

#root,
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background: #000;
  overflow-x: hidden;
}

html {
  font-size: 16px;
}

ul,
ol,
li,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  color: inherit;
}
