.input {
  width: 100%;
  min-height: 2.625rem;
  text-align: left;
  box-sizing: border-box;
  outline: 0;
  padding: 0 1.25rem;
  background: #162348 !important;
  border: 1px solid #087dff;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 14px;
  color: white;

  &::placeholder {
    background: #162348;
    color: white;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus input:-webkit-autofill,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    font-family: "Rubik", sans-serif;
    font-size: 1rem;
    color: white;
    border: 0;
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 174, 255, 0.04) 50%,
      rgba(255, 255, 255, 0) 51%,
      rgba(0, 174, 255, 0.03) 100%
    );
  }
}
