.change-pas {
  width: 94%;
  min-width: 18.75rem;
  box-sizing: border-box;
  // padding: 0.5rem;
  margin: 0 auto;
  color: white;

  &__button {
    margin-top: 1rem;
    width: 80%;
    min-width: 15rem;
    height: 2.2rem;
    background: url(./img/background-button.jpg);
    background-position: 0 0;
    background-size: 100% 100%;
    border-radius: 3.99319px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
    cursor: pointer;
    margin: 8.625rem auto;
    outline: none;
    border: none;
  }
  &__title {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    font-weight: bold;
    margin-bottom: 1rem;
  }
}
