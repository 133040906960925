.locale {
  width: 88%;
  min-width: 18.75rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    width: 100%;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0.7rem;
    box-sizing: border-box;
    font-weight: 400;
    text-transform: capitalize;
    background: #0c4593;
    border-radius: 5px;
    position: relative;
    color: white;
    margin-bottom: 0.5rem;
  }
  &__arrow {
    position: absolute;
    right: 0.9375rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-svg {
      height: 1rem;
      transform: rotate(90deg);
      & path {
        fill: white;
      }
    }

    &_opened {
      transform: rotate(180deg);
    }
  }

  &__item {
    width: 90%;
    height: 2.8rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 0.7rem;
    background: #162348;
    border: 1px solid #087dff;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 0.25rem;
    color: white;
    &_active {
      background: #087dff;
    }
  }
  &__image {
    width: 1.4rem;
    margin-right: 0.5rem;
  }
  &__bottom {
    box-sizing: border-box;
    /* padding: 0.7em 1em; */
    width: 15rem;
    margin-top: 5rem;
  }
  &__button {
    width: 100%;
    height: 2.8rem;
    font-weight: bold;
    background: url(./img/background-button.jpg);
    background-position: 0 0;
    background-size: 100% 100%;
    border-radius: 3.99319px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
    cursor: pointer;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.38);
    font-weight: bold;
  }
  &__ok {
    width: 1.8rem;
    position: absolute;
    right: 1rem;
  }
}
