.password-pop-up {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 5;
  color: white;

  &__body {
    background: linear-gradient(93.5deg, #61e259 0%, #0078ff 101.96%), #087dff;
    box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.42);
    border-radius: 5px;
    width: 80%;
    height: 15rem;
    padding: 3.3125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    border: 1px solid #323232;
  }
  &__header {
    width: 100%;
    text-align: center;
  }
  &__password {
    font-weight: bold;
    font-size: 1.2857rem;
    text-align: center;
  }

  &__button {
    width: 80%;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.625rem;
    color: #fff;
    cursor: pointer;
  }
}
